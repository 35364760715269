.MuiFormControl-root.Mui-error {
  margin-bottom: 16px;
}

.MuiInputLabel-root.Mui-error {
  color: red;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.MuiFormHelperText-root.Mui-error {
  color: red;
}
